@import url("fonts/SFProDisplay/stylesheet.css");

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.flex_inline {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}
.flex_sb {
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.flex_sw {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
}
.flex_ac {
    -webkit-align-items: center;
    align-items: center;
}
.flex_ab {
    -webkit-align-items: flex-end;
    align-items: flex-end;
}
.flex_at {
    -webkit-align-items: flex-start;
    align-items: flex-start;
}
.flex_hc {
    justify-content: center;
}
.flex_hr {
    justify-content: flex-end;
}
.flex_hl {
    justify-content: flex-start;
}
.flex_center {
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.flex_vertical {
    -webkit-flex-direction: column;
    flex-direction: column;
}
.flex_wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
.flex_no-wrap {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.flex_grow {
    -webkit-flex-grow: 1;
    flex-grow: 1;
}
.flex_no-grow {
    -webkit-flex-grow: 0;
    flex-grow: 0;
}
.flex_no-shrink {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}
.flex_reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
.flex_reverse-column {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
}
.clickable {
    cursor: pointer;
}
.verticalMiddle {
    top: 50%;
    -ms-transform: translate(0,-50%);
    -webkit-transform: translate(0,-50%);
    transform: translate(0,-50%);
    position: absolute;
}
.itemCenter {
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    position: absolute;
}
.horizontalMiddle {
    left: 50%;
    -ms-transform: translate(-50%,0);
    -webkit-transform: translate(-50%,0);
    transform: translate(-50%,0);
    position: absolute;
}
.clearfix {
    clear: both;
}
.clearfix:after {
    content: "";
    display: block;
    clear: both;
    height: 1px;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
html {
    height: 100%;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}
* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*:focus {
    outline: none;
}
body {
    font-family: 'SF Pro Display', sans-serif;
}
a{
    text-decoration: none;
}
.btn_nodefault {
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
}
.btn {
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 3px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
    cursor: pointer;
}
.btn_full-width {
    width: 100%;
}
.btn_center {
    justify-content: center;
    text-align: center;
}
.restaurant {
    padding: 20px 16px;
}
.restaurant-title {
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    color: #323232;
    margin-bottom: 24px;
}
.restaurant-list {
    margin-bottom: 24px;
}
.restaurant-item {
    margin-bottom: 13px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    position: relative;
    display: block;
    text-decoration: none;
}
.restaurant-item:last-child {
    bottom: unset;
}
.restaurant-item__img-wrap {
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    height: 254px;
    position:relative;
}
.restaurant-item__img {
    width: 100%;
}
.restaurant-item__content {
    border-radius: 15px;
    background: #FFFFFF;
    padding: 16px;
    margin-top: -85px;
    z-index: 2;
    position: relative;
}
.restaurant-item__title {
    font-size: 18px;
    line-height: 21px;
    color: #323232;
    margin-bottom: 3px;
}
.restaurant-item__rating {
    margin-bottom: 2px;
}
.rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.rating-icon {
    margin-right: 4px;
}
.rating-icon .icon {
    color: #F8CA53;
}
.rating-value {
    font-size: 14px;
    line-height: 17px;
    color: #F8CA53;
    margin-right: 5px;
}
.rating-quantity {
    font-size: 14px;
    line-height: 17px;
    color: #B7B7B7;
}
.restaurant-item__delivery {
    font-size: 12px;
    line-height: 14px;
    color: #B7B7B7;
    margin-bottom: 8px;
}
.restaurant-item__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.info-item {
    background: #F2F2F2;
    border-radius: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 29px;
    padding: 0 8px;
    font-size: 14px;
    line-height: 16px;
    color: #323232;
    margin-right: 8px;
}
.info-item:last-child {
    margin-right: unset;
}
.info-item.circle {
    width: 29px;
    padding: unset;
}
.restaurant-item__gift {
    position: absolute;
    bottom: 140px;
    left: 12px;
    border-radius: 11px;
    background: #FFFFFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.restaurant-item__gift .icon {
    background: #6C9F39;
    border-radius: 10px;
    height: 38px;
    width: 38px;
    color: #FFFFFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.restaurant-item__gift span {
    font-size: 14px;
    line-height: 17px;
    color: #323232;
    padding: 0 8px;
}
.restaurant-item__21 {
    position: absolute;
    top: 0;
    left: 0;
}
.border {
    border-bottom: 0.520833px solid #DFDFDF;
    padding: 0 20px;
    width: 100%;
    height: 1px;
}
.promo {
    padding: 20px 0 20px 16px;
}
.promo-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #323232;
    margin-bottom: 20px;
}
.promo-mini__list-wrap {
    margin-bottom: 20px;
    overflow: hidden;
}
.promo-mini__list {
    overflow: auto;
    padding-right: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.promo-item-mini {
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    margin-right: 13px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    height: 120px;
    width: 120px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}
.promo-item-mini:last-child {
    margin-right: unset;
}
.promo-item-mini__img-wrap {
    width: 100%;
}
.promo-item-mini__img {
    width: 100%;
}
.promo-item-mini__title {
    font-size: 12px;
    line-height: 14px;
    color: #323232;
    position: absolute;
    bottom: 12px;
    left: 12px;
    width: 100%;
}
.promo-wide__list {
    overflow: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.promo-item-wide {
    width: 356px;
    height: 179px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    margin-right: 13px;
    margin-bottom: 24px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}
.promo-item-wide:last-child {
    margin-right: unset;
}
.promo-item-wide__img-wrap {
    width: 100%;
}
.promo-item-wide__img {
    width: 100%;
}
.promo-item-wide__title {
    font-size: 18px;
    line-height: 21px;
    color: #323232;
    position: absolute;
    bottom: 15px;
    left: 16px;
    width: 100%;
}
.product {
    padding: 20px 16px;
}
.product-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #323232;
    margin-bottom: 20px;
}
.product-list__wrap {
    margin-bottom: 24px;
}
.product-list {
    overflow: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.product-item {
    position: relative;
    width: 317px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    margin-right: 13px;
    margin-bottom: 10px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    overflow: hidden;
}
.product-item__img-wrap {
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
}
.product-item__img {
    width: 100%;
}
.product-item__title {
    font-size: 18px;
    line-height: 21px;
    color: #323232;
    margin-bottom: 3px;
}
.product-item__content {
    border-radius: 15px;
    background: #FFFFFF;
    padding: 16px 7px 16px 16px;
    margin-top: -85px;
    z-index: 2;
    position: relative;
}
.product-item__delivery {
    font-size: 12px;
    line-height: 14px;
    color: #B7B7B7;
    margin-bottom: 8px;
}
.product-item__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}

.static-text{
    white-space: pre-wrap;
}
.main-container{
    max-width: 500px;
    margin: auto;
}

.menu-element{
    margin-left:20px;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 10px;
}

.menu-add{
    margin-left: auto;
}
.menu-number{
    width: 70px;
}

.menu-img{
    width: 50px;
    margin-right: 10px;
}

.cart-button{
    position: fixed;
    background: #6C9F39;
    height: 30px;
    border-radius:30px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding:0 10px;
    color: #fff;
    bottom: 5px;
    z-index: 10;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.cart-element{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 20px;
}

.cart-remove{
    margin-left: auto;
}

.itog-sum{
    text-align: right;
}

/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #29d;

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1.0;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
}

#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: #29d;
    border-left-color: #29d;
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.directionList{
    display: flex;
    align-items: center;
    overflow: auto;
}

.directionElement{
    border: 1px solid #B7B7B7;
    padding: 0 20px;
    white-space: nowrap;
}

.panel-slide {
    display: none;
    position: absolute;
    bottom: -100%;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
}

.panel-slide.active {
    bottom: 0;
    display: block;
}
.ProductFullContent{
    max-width: 500px;
    margin: auto;
}

[data-rsbs-header] {
    margin-top: -20px;
    position: relative;

}

[data-rsbs-header]:before{
    background: rgba(255,255,255,0.7);
}

[data-rsbs-overlay] {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
[data-rsbs-footer]{
    position: relative;
}



@media (max-width: 768px) {
    /* Hide scrollbar for Chrome, Safari and Opera */
    *::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge add Firefox */
    * {
        -ms-overflow-style: none;
        scrollbar-width: none; /* Firefox */
    }
}

h1:not([class]){
    font-weight: bold;
    margin-bottom: 10px;
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;

}
.my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
}

@media (min-width: 768px) {
    .my-masonry-grid {
        margin-left: -30px;
    }

    .my-masonry-grid_column {
        padding-left: 30px;
    }
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
}

/*.__jivoMobileButton {
    display: none !important;
    visibility: hidden !important;
}*/


.footer-wrapper {
    background: #333;
}
.footer {
    height: 400px;
    padding: 53px 0 0;
    position: relative;
}
.container, .container-fluid {
    margin: 0 auto;
    position: relative;
    max-width: 988px;
}

.row {
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
}

.footer__first-col {
    padding-bottom: 100px;
    padding-left: 0;
}
.col-3 {
    width: 252px;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-fluid-1, .col-fluid-2, .col-fluid-3, .col-fluid-4, .col-fluid-5, .col-fluid-6, .col-fluid-7, .col-fluid-8, .col-fluid-9, .col-fluid-10, .col-fluid-11, .col-fluid-12 {
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
}

.footer__col-header {
    color: #fff;
    font: normal 16px/1.1 ;
    margin-bottom: 23px;
    text-transform: uppercase;
}
.v-nav {
    display: block;
}
.v-nav__item {
    display: block;
    line-height: 1.2;
    list-style: none;
    margin: 0 0 15px;
    padding: 0;
}
.v-nav__link {
    color: #e1e1e1;
    text-decoration: none;
}

.footer__mobile-links {
    padding-top: 3px;
}

.mobile-links {
}

.mobile-links__store.mobile-links__store_apple {
    background: #0f0f0f url(https://makhachkala.edostav.ru/images/v2.0/app-stores.svg) 15px 7px no-repeat;
}
.mobile-links__store:first-child {
    margin-top: 0;
}
.mobile-links__store {
    background: #0f0f0f;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: block;
    height: 51px;
    margin: 15px 0 0;
    vertical-align: top;
    width: 152px;
    zoom: 1;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.mobile-links__store.mobile-links__store_google {
    background: #0f0f0f url(https://makhachkala.edostav.ru/images/v2.0/app-stores.svg) -169px 6px no-repeat;
}

.footer__mobile-links {
    padding-top: 3px;
}

.footer__bottom {
    border-top: 1px solid #535353;
    color: #b2b2b2;
    height: 79px;
    width: 1008px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.row {
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
}



@media (min-width: 768px) {
    .pos-desk-sticky{
        position: sticky;
        top: 0;
        z-index: 3;
    }
}

.popup-overlay {
    background: rgba(0,0,0,.5);
    overflow: auto;
    padding: 20px 0;
}

.react-confirm-alert-overlay{
    z-index: 9999!important;
}


:root {
    --color-fg-on-emphasis: #ffffff;
    --color-neutral-emphasis-plus: #24292f;
}



.toolTip {
    display: inline-block;
    text-decoration: none;
    animation-name: tooltip-appear;
    animation-duration: .1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    animation-delay: .4s;
    right: 100%;
    bottom: 50%;
    margin-right: 6px;
    transform: translateY(50%);
    position: absolute;
    z-index: 1000000;
    padding: 0.5em 0.75em;
    -webkit-font-smoothing: subpixel-antialiased;
    color: var(--color-fg-on-emphasis);
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: break-word;
    white-space: pre;
    content: attr(aria-label);
    background: var(--color-neutral-emphasis-plus);
    border-radius: 6px;
}



 .toolTip::before {
     position: absolute;
     z-index: 1000001;
     width: 0;
     height: 0;
     color: var(--color-neutral-emphasis-plus);
     pointer-events: none;
     content: "";
     border: 6px solid transparent;
 }
.toolTip::before {
    top: 50%;
    bottom: 50%;
    right: -12px;
    margin-top: -6px;
    border-left-color: var(--color-neutral-emphasis-plus);
}

.react-confirm-alert-blur{
    filter: blur(0px) !important;
    -webkit-filter: blur(0px) !important;
}
